"use client"

import Lottie from "react-lottie"

interface LottieWrapperProps {
  loop?: boolean
  autoplay?: boolean
  animationData: any
}

export const LottieWrapper = ({
  loop = true,
  autoplay = true,
  animationData,
}: LottieWrapperProps) => {
  const defaultOptions = {
    loop,
    autoplay,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <Lottie
      options={defaultOptions}
      height={280}
      width={280}
      isStopped={false}
      isPaused={false}
      isClickToPauseDisabled={true}
      style={{ cursor: "default" }}
    />
  )
}
