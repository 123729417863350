"use client"

import { CaptchaManager } from "components/CaptchaManager/CaptchaManager"
import { Button } from "components/Form/Button"
import { CustomLink } from "components/Form/CustomLink"
import { FormActionError } from "components/Form/FormActionError"
import { InputContainer } from "components/Form/InputContainer"
import { SquareCheckbox } from "components/Form/SquareCheckbox"
import { TextInput } from "components/Form/TextInput"
import { SessionConnected } from "components/SessionConnected/SessionConnected"
import { useSignInForm } from "components/SignInForm/hooks/useSignInForm"
import dynamic from "next/dynamic"
import { useRouter, useSearchParams } from "next/navigation"
import { useEffect } from "react"

const SignInFormEmailSent = dynamic(() =>
  import("components/SignInForm/SignInFormEmailSent").then(
    mod => mod.SignInFormEmailSent
  )
)

export const SignInForm = () => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const {
    selectors: {
      errors,
      rememberMe,
      emailSent,
      actionError,
      isSigningIn,
      isConnected,
      isValid,
      tries,
    },
    actions: {
      handleSignInForm,
      register,
      handleEmailSentReset,
      handleSignInAgain,
      setCaptchaInfo,
    },
  } = useSignInForm()

  useEffect(() => {
    const account = searchParams.get("account")

    if (account === "create") {
      const orgId = searchParams.get("orgId")
      const bundle = searchParams.get("bundle")

      router.replace(`/wallet-connect?orgId=${orgId}&bundle=${bundle}`)
    }
  }, [])

  if (isConnected) {
    return <SessionConnected handleSignInAgain={handleSignInAgain} />
  }

  if (emailSent) {
    return <SignInFormEmailSent handleEmailSentReset={handleEmailSentReset} />
  }

  return (
    <form onSubmit={handleSignInForm} className="flex flex-col gap-4">
      <InputContainer
        id="email"
        label="Email address"
        error={errors.email?.message}
      >
        <TextInput
          id="email"
          type="email"
          registeredField={register("email")}
          maxLength={255}
        />
      </InputContainer>
      <InputContainer
        id="password"
        label="Password"
        error={errors.password?.message}
      >
        <TextInput
          id="password"
          type="password"
          registeredField={register("password")}
        />
      </InputContainer>
      <div className="flex justify-between text-base items-center">
        <SquareCheckbox
          id="rememberMe"
          label="Remember me"
          type="check"
          className="-ms-2"
          checked={rememberMe}
          registeredField={register("rememberMe")}
        />

        <CustomLink href="/forgot-password">Forgot your password?</CustomLink>
      </div>
      {actionError && <FormActionError message={actionError} />}
      <div className="text-right md:w-2/3 w-full mx-auto flex flex-col gap-4">
        <div className="md:w-3/4 w-full mx-auto flex flex-col gap-4">
          <Button
            type="submit"
            label="Sign in"
            disabled={isSigningIn || !isValid}
            isLoading={isSigningIn}
          />

          {/* <div className="flex gap-4 items-center py-2">
            <hr className="w-full" />
            <span className="text-base text-gray-400">or</span>
            <hr className="w-full" />
          </div> */}
        </div>

        {/* <Button
          disabled
          variant="secondary"
          label="Continue with Google"
          icon={GoogleIcon}
        />
        <Button
          disabled
          variant="secondary"
          label="Continue with Facebook"
          icon={FacebookIcon}
        /> */}

        <div className="text-center text-base pt-2">
          Don&apos;t have an account?
          <br />
          <CustomLink href="/sign-up" className="underline">
            Sign Up!
          </CustomLink>
        </div>
      </div>
      <CaptchaManager getCaptchaInfo={setCaptchaInfo} key={tries} />
    </form>
  )
}
